<template>
    <div class="column-start h-100">
        <div class="mb-40 lg-12 row-between">
            <h1 class="f-28-black f-black">QR Admins</h1>
            <primary-button
                class="s-align-5"
                :class="[
                            mScreen ? 'w-100 mb-5 mr-0': 'mr-8'
                        ]"
                @click="$router.push({ name: 'AddQRNewUser' })"
            >
                <plus-icon class="i-white mr-6"/>
                Add New User
            </primary-button>
        </div>
        <div class="w-100">
            <loader v-if="isLoading"/>
            <template v-else-if="!isLoading && dataTable.length">
                <dynamic-table
                    :header="headTables.tableHeader"
                    :options-for-render="headTables.optionsForRender"
                    :table-data="dataTable"
                    :is-row-action="true"
                    @onClick="redirectTo"
                />
                <div class="row-center pv-8">
                    <load-more
                        v-if="users?.current_page < users?.last_page"
                        :status="isLoading"
                        @click="getUsers(true)"
                    />
                </div>
            </template>
            <div
                v-else
                class="h-100 w-100 s-align-5"
            >
                <p class="f-18-grey">Your page is empty</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DynamicTable from "../../components/DynamicTable";
import PrimaryButton from "../../ui/base/PrimaryButton";
import PlusIcon from "../../icons/PlusIcon";
import LoadMore from "../../ui/base/LoadMore";

export default {
    name: "QRUsers",
    components: {
        DynamicTable,
        PrimaryButton,
        PlusIcon,
        LoadMore
    },
    computed: {
        ...mapGetters({
            users: "qrUsers/users",
            isLoading: "qrUsers/isLoading"
        }),
        headTables() {
            return {
                tableHeader: ['Name','Company Name', 'Email', 'Unique URL'],
                optionsForRender: ['first_name', 'company_name', 'email', 'code']
            }
        },
        dataTable() {
            return this.users?.data || [];
        }
    },
    methods: {
        ...mapActions({
            getQrUsers: "qrUsers/getQrUsers"
        }),
        redirectTo(item) {
            this.$router.push({
                name: 'QRUser',
                params: {id: item?.id},
            })
        },
        getUsers(isLoadMore) {
            let page = this.users?.current_page ?? 1;
            if (isLoadMore) page += 1;
            const params = {page}
            this.getQrUsers(params);
        }
    },
    created() {
        this.getUsers();
    }
}
</script>

<style scoped>

</style>
