<template>
    <div>
        <div class="lg-12 row-baseline pointer mb-8 f-15-grey" @click="$router.back()">
            <arrow-shewron
                :transform="180"
                width="14"
                height="14"
                class="i-green mr-2"
            />
            QR Admins
        </div>
        <ManufacturerItemList/>
    </div>
</template>

<script>
import ArrowShewron from "../../icons/ArrowShewron";
import ManufacturerItemList from "../../components/ManufacturerItemList";

export default {
    name: "QRItems",
    components: {
        ManufacturerItemList,
        ArrowShewron
    }
}
</script>

<style scoped>

</style>
