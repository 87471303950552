<template>
    <div class="w-100 row-end">
        <primary-button

            class="mr-3"
            :class="[mScreen ? 'w-80 mh-auto' : '']"
            @click="toggleManufacturerModal"
        >
            <plus-icon class="i-white mr-3"/>
            Add QR code
        </primary-button>
    </div>
    <div
        v-if="!isLoading"
        class="w-100 h-100 mt-20"
    >
        <form-input
            id="searchVal"
            class="mb-20"
            label="Search by model, serial number"
            name="searchVal"
            placeholder="Model or Serial number"
            v-model="searchVal"
        />
        <div
            v-if="manufacturerItems.length"
            class="w-100 h-100"
        >
            <dynamic-table
                class="w-100"
                :header="tableHeader"
                :tableData="manufacturerItems"
                :optionsForRender="optionsForRender"
                :showEditBtn="true"
                @onEdit="toggleManufacturerModal"
                @onClick="redirectTo"
                :is-row-action="true"
                :isRemoveBtn="true"
                @onRemove="toggleRemoveModal"
            >
                <template #url="{item}">
                    <a
                        class="ml-5 mr-auto"
                        :href="`${hostName}/manufacturer/${item.url}?mode=preview`"
                        target="_blank"
                        @click.stop="null"
                    >
                        <share-icon class="i-green mr-4"/>
                        {{ item.url }}
                    </a>
                </template>
            </dynamic-table>
        </div>
        <div
            v-else
            class="h-100 w-100 s-align-5"
        >
            <p class="f-18-grey">Manufacturer items is empty</p>
        </div>
    </div>
    <loader v-else/>
    <create-manufacturer-modal
        v-if="isManufacturerModal"
        :edit-data="editManufacturerData"
        @onClose="toggleManufacturerModal"
        @submit="createManufacturerHandler"
    />
    <remove-modal
        v-if="!!removeModal"
        :data="removeModal"
        :name="removeModal?.name"
        @close="removeModal = null"
        @submit="removeManufactureItem"
    />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DynamicTable from "./DynamicTable";
import ShareIcon from "../icons/ShareIcon";
import {Form} from "vee-validate";
import PrimaryButton from "../ui/base/PrimaryButton";
import PlusIcon from "../icons/PlusIcon";
import CreateManufacturerModal from "../ui/modal/CreateManufacturerModal";
import RemoveModal from "../ui/modal/RemoveModal";

export default {
    name: "ManufacturerItemList",
    components: {
        DynamicTable,
        ShareIcon,
        Form,
        PrimaryButton,
        PlusIcon,
        CreateManufacturerModal,
        RemoveModal
    },
    data() {
        return {
            tableHeader: ['Name', 'Model', 'Serial Number', 'Unique URL'],
            optionsForRender: ['name', 'model', 'serial_number', 'url'],
            searchVal: '',
            isManufacturerModal: false,
            editManufacturerData: null,
            removeModal: null,
        }
    },
    props: {
        userId: {
            type: [String, Number],
            default: null
        }
    },
    computed: {
        ...mapGetters({
            hostName: 'getHostName',
            manufacturerItemsData: 'manufacturer/manufacturerItems',
            isLoading: "manufacturer/isLoading",
        }),
        manufacturerItems() {
            const filteredItems = !!this.searchVal.trim()
                ? this.manufacturerItemsData.filter(el =>
                    !!~el.model?.toLowerCase()?.indexOf(this.searchVal?.toLowerCase())
                    || !!~el.serial_number?.toLowerCase()?.indexOf(this.searchVal?.toLowerCase()))
                : this.manufacturerItemsData;
            return filteredItems.map(el => {
                return {
                    ...el,
                    url: el?.code
                }
            }) ?? [];
        },
    },
    methods: {
        ...mapActions({
            createManufacturer: "manufacturer/createManufacturer",
            getManufacturerItemsData: "manufacturer/getManufacturerItems",
            updateManufacturer: "manufacturer/updateManufacturer",
            removeManufacturerItem: "manufacturer/removeManufacturerItem"
        }),
        redirectTo(item) {
            this.$router.push({name: 'ManufacturerItem', params: {id: item.code}})
        },
        toggleRemoveModal(item) {
            this.removeModal = item;
        },
        toggleManufacturerModal(item) {
            this.editManufacturerData = item?.id ? item : null;
            this.isManufacturerModal = !this.isManufacturerModal;
        },
        createManufacturerHandler(data) {
            if (this.editManufacturerData) {
                this.updateManufacturer({id: this.editManufacturerData?.id, data}).then(res => {
                    if (res) {
                        this.getManufacturerItems()
                        this.toggleManufacturerModal();
                    }
                })
            } else {
                if (this.userId){
                    data.user_id = this.userId;
                }
                this.createManufacturer(data).then(res => {
                    if (res) {
                        this.getManufacturerItems()
                        this.toggleManufacturerModal();
                    }
                })
            }
        },
        removeManufactureItem() {
            const id = this.removeModal?.id;
            this.removeManufacturerItem(id).then(res => {
                if (res) {
                    this.removeModal = false;
                    this.getManufacturerItems();
                }
            })
        },
        getManufacturerItems(){
            const id = this.userId || this.$route.params?.id
            this.getManufacturerItemsData(id);
        }
    },
    created() {
        this.getManufacturerItems();
    }
}
</script>

<style scoped>

</style>
