<template>
    <ManufacturerItemList
        v-if="isQRAdmin"
        :user-id="userId"
    />
    <div v-if="!loading && !isQRAdmin">
        <div
            v-if="userById"
            class="lg-12 row-baseline pointer mb-8 f-15-grey"
            @click="redirectTo()"
        >
            <arrow-shewron
                :transform="180"
                width="14"
                height="14"
                class="i-green mr-2"
            />
            All Users
        </div>
        <div class="column-start mb-25">
            <p class="f-15-grey mb-2" v-if="!userById">Your Page</p>
            <div class="row-between row-baseline">
                <div class="lg-6 sm-12">
                    <p
                        v-if="fullName"
                        class="f-28-black f-black row-baseline"
                    >
                        {{ fullName }}
                        <permission-checker
                            :roles="[
                                USER_ROLES.INDIVIDUAL_PAID,
                                USER_ROLES.BUSINESS_PAID,
                                USER_ROLES.ADMIN
                            ]"
                        >
                            <star-icon
                                class="ml-4 i-yellow"
                            />
                        </permission-checker>
                    </p>
                </div>
                <permission-checker
                    :roles="[
                        USER_ROLES.INDIVIDUAL_PAID,
                        USER_ROLES.BUSINESS_PAID,
                        USER_ROLES.ADMIN
                    ]"
                >
                    <div
                        v-if="!userById"
                        :class="[
                            'lg-2 sm-12',
                            {'mt-1': mScreen}
                        ]"
                    >
                        <profle-cards-switcher/>
                    </div>
                </permission-checker>
            </div>
        </div>
        <div class="column-start">
            <profile-card
                v-if="Object.keys(cardInfo).length"
                :cardInfo="cardInfo"
                :key="cardId"
            />
            <template v-if="!userById">
                <div class="row-center s-align-5 pv-8 profile-page-link-banner">
                    <a class="f-20-black text-center" href="https://retrevcard.com/" target="_blank">Click here to view our
                        products</a>
                </div>
                <div class="row-center retrev-products">
                    <a
                        href="https://retrevcard.com/products/retrev-plastic-digital-business-card"
                        target="_blank"
                        class="retrev-products__card-link"
                    >
                        <img src="@/assets/images/pic_cards.jpg" class="retrev-products__card-img"/>
                    </a>
                    <a
                        href="https://retrevcard.com/products/metal-digital-business-card-1"
                        target="_blank"
                        class="retrev-products__card-link"
                    >
                        <img src="@/assets/images/custom_aluminum.jpg" class="retrev-products__card-img"/>
                    </a>
                    <a
                        href="https://retrevcard.com/products/aluminum-metal-digital-business-card "
                        target="_blank"
                        class="retrev-products__card-link"
                    >
                        <img src="@/assets/images/metal_card.jpg" class="retrev-products__card-img"/>
                    </a>
                </div>
            </template>
        </div>
    </div>
    <loader v-else/>
</template>

<script>
import {ProfileCard, PermissionChecker, ProfleCardsSwitcher} from '@/components';
import {ShareIcon, StarIcon} from '@/icons';
import {USER_ROLES} from '@/constants';
import {mapGetters} from 'vuex';
import {ArrowShewron} from "../../icons";
import ManufacturerItemList from "../../components/ManufacturerItemList";

export default {
    name: 'Profile',
    components: {
        ProfileCard,
        PermissionChecker,
        ProfleCardsSwitcher,
        ShareIcon,
        StarIcon,
        ArrowShewron,
        ManufacturerItemList
    },
    data() {
        return {
            USER_ROLES,
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            userRole: "user/getUserRole",
            userId: "user/getUserId",
            cardUser: 'cards/getUserCard',
            cardProfile: 'cards/getProfileUserCard',
            cardId: 'user/getCardId'
        }),
        isQRAdmin() {
            return this.userRole === USER_ROLES.QR_ADMIN
        },
        userById() {
            return this.$route.params?.user
        },
        cardInfo() {
            return !this.userById ? this.cardProfile : this.cardUser;
        },
        fullName() {
            let fullName = '';
            if (this.cardInfo && Object.keys(this.cardInfo).length) {
                fullName = this.cardInfo?.first_name || '';
                fullName += this.cardInfo?.last_name ? ` ${this.cardInfo?.last_name}` : '';
            }

            return fullName;
        },
    },
    methods: {
        getCardsByAuthUser() {
            this.loading = true;
            const dispatchAction = !!this.userById ? 'GET_CARDS_BY_USER_ID' : 'GET_CARDS_BY_AUTH_USER';
            this.$store.dispatch(`cards/${dispatchAction}`, this.userById)
                .then((data) => {
                })
                .catch(error => {
                    this.$store.dispatch('notifications/SHOW_REQUEST_ERROR_NOTIFICATION', error);
                })
                .finally(() => this.loading = false);
        },
        redirectTo() {
            let name = this.$route.query?.businessUserId  ? 'BusinessUsersList' : 'Users';
            this.$router.push({ name, query: this.$route.query })
        }
    },
    mounted() {
        this.getCardsByAuthUser();
    },
}
</script>
